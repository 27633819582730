import { Search as SearchIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useService } from '../../providers/ServiceProvider.js';

interface DurationStats {
  min: number;
  max: number;
  avg: number;
  median: number;
  stddev: number;
  p75: number;
  p90: number;
  p95: number;
  p99: number;
}

interface TestResult {
  projectId: string;
  testId: string;
  runCount: number;
  duration: DurationStats;
  variability: {
    cv: number;
    reliability: 'Good' | 'Moderate' | 'Poor';
  };
  timing: {
    lastRun: string;
    firstRun: string;
  };
}

interface TestStatistics {
  metadata: {
    totalTests: number;
    globalStats: {
      totalRuns: number;
      averageDuration: number;
      standardDeviation: number;
    };
    queriedAt: string;
  };
  tests: TestResult[];
}

type SortField = 'cv' | 'runCount' | 'avg' | 'max';

const TestStatisticsDashboard = () => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<SortField>('cv');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const client = useService();
  const [data, setData] = useState<TestStatistics | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTestStatistics = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await client.fetch('/api/development/test/statistics');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error('Error fetching test statistics:', error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    fetchTestStatistics().catch(console.error);
  }, []);

  const filteredAndSortedTests = useMemo(() => {
    if (!data?.tests) return [];

    const searchTermLower = searchTerm.toLowerCase();
    return data.tests
      .filter((test) => {
        const projectIdMatch = test.projectId.toLowerCase().includes(searchTermLower);
        const testIdMatch = test.testId.toLowerCase().includes(searchTermLower);
        return projectIdMatch || testIdMatch;
      })
      .sort((a, b) => {
        const getValue = (test: TestResult): number => {
          switch (sortField) {
            case 'cv':
              return test.variability.cv;
            case 'runCount':
              return test.runCount;
            case 'avg':
              return test.duration.avg;
            case 'max':
              return test.duration.max;
          }
        };

        const aValue = getValue(a);
        const bValue = getValue(b);
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      });
  }, [data?.tests, searchTerm, sortField, sortDirection]);

  const handleSort = useCallback((field: SortField) => {
    setSortField((currentField) => {
      if (currentField === field) {
        setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        return field;
      }
      setSortDirection('desc');
      return field;
    });
  }, []);

  const getReliabilityColor = useCallback(
    (reliability: string) => {
      const colors = {
        Good: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.common.white,
          fontWeight: 500
        },
        Moderate: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.common.white,
          fontWeight: 500
        },
        Poor: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          fontWeight: 500
        }
      } as const;

      return (
        colors[reliability as keyof typeof colors] ?? {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[900],
          fontWeight: 500
        }
      );
    },
    [theme]
  );

  const StatCard = ({ title, value, unit = '' }: { title: string; value: number; unit?: string }) => (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4">{loading ? <Skeleton /> : `${value.toFixed(2)}${unit}`}</Typography>
      </CardContent>
    </Card>
  );

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        Error loading test statistics: {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <StatCard title="Total Tests" value={data?.metadata.totalTests ?? 0} />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard title="Total Runs" value={data?.metadata.globalStats.totalRuns ?? 0} />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard title="Avg Duration" value={data?.metadata.globalStats.averageDuration ?? 0} unit="s" />
        </Grid>
      </Grid>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Project ID or Test ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Test Info</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'runCount'}
                  direction={sortField === 'runCount' ? sortDirection : 'asc'}
                  onClick={() => handleSort('runCount')}
                >
                  Runs
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'avg'}
                  direction={sortField === 'avg' ? sortDirection : 'asc'}
                  onClick={() => handleSort('avg')}
                >
                  Avg Duration
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'max'}
                  direction={sortField === 'max' ? sortDirection : 'asc'}
                  onClick={() => handleSort('max')}
                >
                  Max Duration
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'cv'}
                  direction={sortField === 'cv' ? sortDirection : 'asc'}
                  onClick={() => handleSort('cv')}
                >
                  Variability
                </TableSortLabel>
              </TableCell>
              <TableCell>Last Run</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={6}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))
              : filteredAndSortedTests.map((test) => (
                  <TableRow key={`${test.projectId}-${test.testId}`} hover>
                    <TableCell>
                      <Typography variant="subtitle2">
                        <Link to={`/projects/${test.projectId}`}>Project {test.projectId}</Link>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <Link to={`/projects/${test.projectId}/tests/${test.testId}`}>
                          Test {test.testId}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{test.runCount.toLocaleString()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{test.duration.avg.toFixed(2)}s</Typography>
                      <Typography variant="caption" color="textSecondary">
                        min: {test.duration.min.toFixed(2)}s
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{test.duration.max.toFixed(2)}s</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={`${test.variability.cv.toFixed(2)}% (${test.variability.reliability})`}
                        size="small"
                        sx={{ ...getReliabilityColor(test.variability.reliability) }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {new Date(test.timing.lastRun).toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TestStatisticsDashboard;
