import { Worker } from '@analyzer/client';
import { useQuery } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useWorkerListQuery(queryParams?: { limit?: number; offset?: number }) {
  const client = useService();
  return useQuery<Worker[], Error>(['worker-list', queryParams], () => client.workerList(queryParams), {
    keepPreviousData: true
  });
}

export function useWorkerReadQuery(workerId: number | null) {
  const client = useService();
  return useQuery<Worker, Error>({
    queryKey: ['worker-read', workerId],
    queryFn: () => client.workerRead(workerId!),
    enabled: !!workerId
  });
}
