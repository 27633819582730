import { Snapshot, SnapshotType } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useSnapshotCreateMutation(type: SnapshotType) {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { projectId: number; assetId: number }) =>
      client.snapshotCreate(type, data.projectId, data.assetId),
    {
      onSuccess: () => queryClient.invalidateQueries([`${type}-snapshot-list`])
    }
  );
}

export function useSnapshotListQuery(
  type: SnapshotType,
  projectId: number,
  queryParams?: { limit?: number; offset?: number }
) {
  const client = useService();
  return useQuery<Snapshot[], Error>(
    [`${type}-snapshot-list`, projectId, queryParams],
    () => client.snapshotList(type, projectId, queryParams),
    {
      keepPreviousData: true
    }
  );
}

export function useSnapshotReadQuery(type: SnapshotType, projectId: number, snapshotId: number) {
  const client = useService();
  return useQuery<Snapshot, Error>({
    queryKey: [`${type}-snapshot-read`, projectId, snapshotId],
    queryFn: () => client.snapshotRead(type, projectId, snapshotId)
  });
}

export function useSnapshotDestroyMutation(type: SnapshotType) {
  const client = useService();
  return useMutation((data: { projectId: number; snapshotId: number }) =>
    client.snapshotDestroy(type, data.projectId, data.snapshotId)
  );
}
