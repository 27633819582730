import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useAssetListQuery(projectId: number, queryParams?: { limit?: number; offset?: number }) {
  const client = useService();
  return useQuery<any[], Error>(
    ['asset-list', projectId, queryParams],
    () => client.assetList(projectId, queryParams),
    {
      keepPreviousData: true
    }
  );
}

export function useAssetCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: {
      projectId: number;
      assetData: { hostname: string; address: string; port: number; secure: boolean };
    }) => client.assetCreate(data.projectId, data.assetData),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['asset-list']);
      }
    }
  );
}

export function useAssetReadQuery(projectId: number, assetId: number) {
  const client = useService();
  return useQuery<any, Error>({
    queryKey: ['asset-read', projectId, assetId],
    queryFn: () => client.assetRead(projectId, assetId)
  });
}

export function useAssetUpdateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: {
      projectId: number;
      assetId: number;
      updateData: { hostname?: string; address?: string; port?: number; secure?: boolean };
    }) => client.assetUpdate(data.projectId, data.assetId, data.updateData),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['asset-list']);
      }
    }
  );
}

export function useAssetDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { projectId: number; assetId: number }) => client.assetDestroy(data.projectId, data.assetId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['asset-list']);
      }
    }
  );
}
