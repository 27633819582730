import {
  Backdrop,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useWorkerListQuery, useWorkerReadQuery } from '../api/worker.js';
import { timeToNow } from '../components/utils.js';
import { Link, useParams } from 'react-router-dom';

function getChipColor(status: string) {
  switch (status) {
    case 'started':
      return 'primary';
    case 'stopped':
      return 'success';
    case 'failed':
      return 'error';
    default:
      return 'default';
  }
}

function WorkerTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    data: workers,
    isLoading,
    error
  } = useWorkerListQuery({ limit: rowsPerPage, offset: page * rowsPerPage });

  if (isLoading)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  if (error) return <div>Error: {error.message}</div>;

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Tick</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Error</TableCell>
              <TableCell>Tag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workers.map((worker) => (
              <TableRow key={worker.id}>
                <TableCell>
                  <Link to={`/workers/${worker.id}`}>{worker.id}</Link>
                </TableCell>
                <TableCell>{timeToNow(worker.createdDate)}</TableCell>
                <TableCell>{timeToNow(worker.tick)}</TableCell>
                <TableCell>
                  <Chip label={worker.status} color={getChipColor(worker.status)} size="small" />
                </TableCell>
                <TableCell>
                  <Tooltip title={worker.error?.stack} placement="top" arrow>
                    <span>{worker.error ? worker.error.message : 'N/A'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{worker.tag}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function WorkersView() {
  return <WorkerTable />;
}

export function WorkerView() {
  const { workerId } = useParams();
  if (!workerId) {
    throw new Error('Worker ID is missing from the URL');
  }

  const { data: worker, isLoading, isError } = useWorkerReadQuery(parseInt(workerId));

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !worker) {
    return <Typography variant="body1">Unable to load project details. Please try again later.</Typography>;
  }

  return <pre>{JSON.stringify(worker, null, 2)}</pre>;
}
