import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';
import { Job } from '@analyzer/client';

export function useJobListQuery(queryParams?: { limit?: number; offset?: number }) {
  const client = useService();
  return useQuery<Job[], Error>(['job-list', queryParams], () => client.jobList(queryParams), {
    keepPreviousData: true
  });
}

export function useJobReadQuery(jobId: number) {
  const client = useService();
  return useQuery<Job, Error>({
    queryKey: ['job-read', jobId],
    queryFn: () => client.jobRead(jobId)
  });
}

export function useJobDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((jobId: number) => client.jobDestroy(jobId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['job-list']);
    }
  });
}
