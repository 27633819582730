import { ObfuscationAlertAPI } from '@analyzer/client';
import { Alert, AlertTitle, Box, Card, CardContent, Chip, Divider, Paper, Typography } from '@mui/material';
import { getSeverityColor } from './severity.js';

export function ObfuscatedCodeAlert({ alert }: { alert: ObfuscationAlertAPI }) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle sx={{ fontSize: '1.1rem' }}>
          Alert: Obfuscated Code Detected
          <Chip
            label={alert.severity.toUpperCase()}
            color={getSeverityColor(alert.severity)}
            size="small"
            sx={{ ml: 2 }}
          />
        </AlertTitle>
        <Typography variant="body2">
          The system has detected obfuscated code, which may indicate hidden or malicious functionality.
        </Typography>
      </Alert>

      <Paper elevation={2}>
        <Card>
          <CardContent>
            <Box sx={{ '& > :not(:last-child)': { mb: 3 } }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Obfuscated code has been identified in the system. The detected obfuscation method is{' '}
                  <strong>{alert.reason}</strong>. This type of code is often used to conceal functionality
                  and may pose security risks.
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="h6" gutterBottom>
                  Request Details
                </Typography>
                <Box sx={{ '& > :not(:last-child)': { mb: 1 } }}>
                  <Typography variant="body2">
                    <strong>URL:</strong> {alert.requests[0]!.request.url}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Method:</strong> {alert.requests[0]!.request.method}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Content Type:</strong> {alert.requests[0]!.response.contentType}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Hostname:</strong> {alert.requests[0]!.connection.hostname}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Port:</strong> {alert.requests[0]!.connection.port}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Secure Connection:</strong> {alert.requests[0]!.connection.secure ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
