import { Alert, AlertSeverity } from '@analyzer/client';
import { Box, Paper, Theme, Typography, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import { AntiDebugAlert } from './AntiDebugAlert.js';
import { ContentChangeAlertComponent } from './content-change.js';
import { DependencyChangeAlert } from './DependencyChangeAlert.js';
import { SignatureAlert } from './SignatureAlert.js';
import { ObfuscatedCodeAlert } from './ObfuscatedCodeAlert.js';

export function RawAlertComponent({ alert }: { alert: Alert }) {
  return (
    <>
      <Typography variant="subtitle2">Alert Type</Typography>
      <Typography variant="body2">{alert.type || 'Unknown'}</Typography>

      <Typography variant="subtitle2">Description</Typography>
      <Typography variant="body2">Raw alert data display for debugging purposes.</Typography>

      <Typography variant="subtitle2">Details</Typography>
      <Typography variant="body2">Raw alert object:</Typography>
      <Box component="pre" sx={{ whiteSpace: 'pre-wrap', padding: 0 }}>
        {JSON.stringify(alert, null, 2)}
      </Box>
    </>
  );
}
const renderAlertSpecifics = (alert: Alert) => {
  try {
    switch (alert.type) {
      case 'anti-debug':
        return <AntiDebugAlert alert={alert} />;
      case 'signature-alert':
        return <SignatureAlert alert={alert} />;
      case 'transparency-log-alert':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when the transparency log has been updated with new entries.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              The addition of new entries to the transparency log indicates that a new TLS certificate has
              been issued.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following new origins were detected:</Typography>
            <ul>
              {alert.records.map((record, index) => (
                <li key={index}>{JSON.stringify(record)}</li>
              ))}
            </ul>
          </>
        );
      case 'dependency-change':
        return <DependencyChangeAlert alert={alert} />;

      case 'ethereum-provider-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when new RPC providers are detected in the 'wallet_addEthereumChain'
              method calls made by the DApp that were not present in previous snapshots.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              The addition of new RPC providers could indicate changes in the DApp's communication behavior or
              potential security risks. These new providers should be reviewed to ensure they are reliable and
              do not pose any vulnerabilities or malicious behavior.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following new RPC providers have been added:</Typography>
            <ul>
              {alert.new.map((url, index) => (
                <li key={index}>
                  <strong>RPC Provider URL:</strong> {url}
                </li>
              ))}
            </ul>
          </>
        );

      case 'ethereum-contract-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when new contracts are detected being called by the DApp that were not
              present in previous snapshots.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              Interactions with new contracts could indicate changes in the DApp's behavior or potential
              security risks. These new contracts should be reviewed to ensure they do not introduce any
              vulnerabilities or malicious behavior.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following new contracts have been called:</Typography>
            <ul>
              {alert.contracts.map((contract, index) => (
                <li key={index}>
                  <strong>Contract Address:</strong> {contract}
                </li>
              ))}
            </ul>
          </>
        );

      case 'google-safe-browsing':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when URLs used by the DApp are found in Google's Safe Browsing database.
              These URLs may be associated with known threats, such as phishing or malware distribution.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              The presence of these URLs in your DApp could lead to significant security risks, including
              exposure to phishing, malware, or other malicious activities. It is critical to investigate and
              remediate these issues promptly.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following URL(s) were flagged:</Typography>
            <ul>
              {alert.results.map((result, index) => (
                <li key={index}>
                  <Typography variant="body2">
                    <strong>URL:</strong> {result.url}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Threat Type:</strong> {result.threatType || 'Unknown'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Platform Type:</strong> {result.platformType || 'Unknown'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Threat Entry Type:</strong> {result.threatEntryType || 'Unknown'}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        );

      case 'obfuscated-code':
        return <ObfuscatedCodeAlert alert={alert} />;

      case 'additional-request':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              An Additional Request Alert is triggered when one or more requests are detected that have not
              been seen before. These additional requests can potentially reveal connections to compromised
              hosts, indicating potential security risks. It is important to investigate and analyze such
              requests to ensure the integrity and security of the system.
            </Typography>
            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              The impact of malicious requests can have significant consequences. One potential impact is the
              exfiltration of private data to an attacker-controlled host when a compromised application is
              involved.
            </Typography>
            <Typography variant="subtitle2">Details</Typography>
            {alert.requests.map((request, index) => (
              <Typography variant="body2" key={index}>
                <Link to={request.request.url}>{request.request.url}</Link>
              </Typography>
            ))}
          </>
        );

      case 'insecure-connection':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              An Insecure Connection Alert is triggered when connections that do not use secure protocols
              (like HTTPS) are detected. Secure connections are important as they help keep information
              transferred between your website and its users private and secure.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              Insecure connections can expose sensitive information transferred between your website and its
              users to potential interception by attackers. This could compromise user data and harm your
              site's reputation.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following insecure connections were detected:</Typography>
            {alert.connections.map((connection, index) => (
              <div key={index}>
                <Typography variant="body2">Connection Address: {connection.connection.address}</Typography>
              </div>
            ))}
          </>
        );

      case 'certificate-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered by TLS certificate changes for a specific resource. While certificate
              renewals are common due to expiration, changes can also stem from security incidents, such as
              server hijacking, where an attacker issues a rogue certificate.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              Malicious certificate alterations may compromise server communications, exposing sensitive
              information to attackers, infringing privacy, and escalating security threats.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>

            {alert.change.type === 'unexpected-issuer' && (
              <>
                <Typography variant="body2">Unexpected Certificate Issuer:</Typography>
                <Typography variant="body2" gutterBottom>
                  {alert.change.issuer}
                </Typography>
                <Typography variant="body2">Past Certificate Issuers:</Typography>
                <ul>
                  {alert.change.issuers.map((issuer, idx) => (
                    <li key={idx}>{issuer}</li>
                  ))}
                </ul>
              </>
            )}

            {alert.change.type === 'expected-renewal' && (
              <Typography variant="body2" component="div">
                Expected Certificate Renewal:
                <ul>
                  {alert.change.expirations.map((expiration, idx) => (
                    <li key={idx}>Certificate expires in {expiration} day(s)</li>
                  ))}
                </ul>
              </Typography>
            )}

            {alert.change.type === 'unexpected-renewal' && (
              <Typography variant="body2">
                A certificate renewal occurred unexpectedly, without nearing expiration.
              </Typography>
            )}
          </>
        );

      case 'status-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              A Status Change Alert is issued when the application transitions to an unreachable state. This
              could indicate network issues, server downtime, or unexpected errors within the application.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              When the application becomes unreachable, it may significantly impact user experience, lead to
              data loss, or interrupt critical workflows. Prompt investigation and resolution are crucial to
              minimize potential disruptions.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The application became unreachable at approximately:</Typography>
            <Typography variant="body2">
              <strong>{new Date(alert.timestamp).toLocaleString()}</strong>
            </Typography>
          </>
        );

      case 'content-change':
        return ContentChangeAlertComponent(alert);

      case 'dns-record-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert notifies of alterations in the DNS records for your domain. DNS records are pivotal
              in directing internet traffic, influencing both the accessibility and security of your website.
            </Typography>

            <Typography variant="subtitle2">Impact</Typography>
            <Typography variant="body2">
              Modifications to DNS records can reroute your domain's traffic, potentially to malicious
              destinations if changes are unauthorized. Such alterations may cause your site to become
              inaccessible, increase the risk of security breaches, or expose users to harmful content.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">Notable DNS record changes include:</Typography>
            <ul>
              {alert.changes.map((change, idx) => (
                <li key={idx}>
                  Record of type {change.recordType} with value {change.record} was {change.type}
                </li>
              ))}
            </ul>
          </>
        );
      case 'analyzer-blocked':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              An Analyzer Blocked Alert is triggered when the analyzer bot has been blocked by the target.
            </Typography>
          </>
        );
      case 'deployment-alert':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              A Deployment Alert is triggered when a new deployment has been deployed.
            </Typography>
          </>
        );
    }
  } catch (error) {
    return RawAlertComponent({ alert });
  }
};

const AlertColorMap: Record<AlertSeverity, string> = {
  ['high']: '#ad0424',
  ['medium']: '#d7667b',
  ['low']: '#abe4b4',
  ['info']: '#abbfe4'
};

function usePaperStyles(alertSeverity: string) {
  return (theme: Theme) => ({
    borderLeft: `10px solid ${alertSeverity}`,
    bgcolor: alpha(alertSeverity, 0.01),
    padding: theme.spacing(2)
  });
}

export function AlertComponent({ alert }: { alert: Alert }) {
  const styles = usePaperStyles(AlertColorMap[alert.severity]);

  return <Paper sx={styles}>{renderAlertSpecifics(alert)}</Paper>;
}
