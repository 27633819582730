import { AuthClient } from '@analyzer/client';
import { Alert, AlertTitle, Backdrop, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import { decodeJwt } from 'jose';
import { createContext, useContext, useEffect, useState } from 'react';
import { FRONTEND_SERVICE_URL } from '../settings.js';

function setLocalStorage(key: string, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
}

function getLocalStorage(key: string, initialValue: any) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
}

interface UserInfo {
  email: string;
  id: string;
  name: string;
  picture: string;
}

interface AuthData {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  authenticated: boolean;
  user: UserInfo | null;
}

export const AuthContext = createContext<AuthData>({} as AuthData);

export function AuthProvider({ children }: any) {
  // Try to load the user from the local storage.
  const [token, setToken] = useState<string | null>(() => getLocalStorage('auth-token', null));
  const [error, setError] = useState<string | null>(null);
  const [authenticating, setAuthenticating] = useState(false);
  const [user, setUser] = useState<UserInfo | null>(null);

  // When the user changes, update the storage.
  useEffect(() => {
    if (token) {
      const decoded = decodeJwt(token);
      setUser({
        email: decoded.email as string,
        id: decoded.id as string,
        name: decoded.name as string,
        picture: decoded.picture as string
      });
    }

    setLocalStorage('auth-token', token);
  }, [token]);

  const logout = () => {
    setLocalStorage('auth-token', null);
    setToken(null);
  };

  const login = async (idToken: string) => {
    // Contact the authentication endpoint to get an access token.
    const client = new AuthClient(FRONTEND_SERVICE_URL);

    try {
      setAuthenticating(true);
      const authToken = await client.authorizeUser(idToken);
      setLocalStorage('auth-token', authToken);
      setToken(authToken);
    } catch (error) {
      setError((error as any).toString());
    } finally {
      setAuthenticating(false);
    }
  };

  if (authenticating) {
    return (
      <Backdrop open={true} sx={{ color: '#fff' }}>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="overline">Authenticating to the service ...</Typography>
        </Stack>
      </Backdrop>
    );
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setError(null);
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, authenticated: !!token, user }}>
      {children}
      <Snackbar
        open={!!error}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <AlertTitle>There has been an error authorizing to the service</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('You must provide an AuthContext via AuthProvider');
  }

  return authContext;
}
