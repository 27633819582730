import { useQuery } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useRootListQuery(projectId: number, queryParams?: { limit?: number; offset?: number }) {
  const client = useService();
  return useQuery<any[], Error>(
    ['rule-list', projectId, queryParams],
    () => client.rootList(projectId, queryParams),
    {
      keepPreviousData: true
    }
  );
}
