import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useTestListQuery(projectId: number, queryParams?: { limit?: number; offset?: number }) {
  const client = useService();
  return useQuery<any[], Error>(
    ['test-list', projectId, queryParams],
    () => client.testList(projectId, queryParams),
    {
      keepPreviousData: true
    }
  );
}

export function useTestCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: {
      projectId: number;
      test: { name: string; target: string; actions?: string; initScript?: string };
    }) => client.testCreate(data.projectId, data.test),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['test-list']);
      }
    }
  );
}

export function useTestReadQuery(projectId: number, testId: number | null) {
  const client = useService();
  return useQuery<any, Error>({
    queryKey: ['test-read', testId],
    queryFn: () => client.testRead(projectId, testId!),
    enabled: !!testId
  });
}

export function useTestUpdateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: {
      projectId: number;
      testId: number;
      test: { name?: string; target?: string; actions?: string; initScript?: string };
    }) => client.testUpdate(data.projectId, data.testId, data.test),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['test-list']);
      }
    }
  );
}

export function useTestDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { projectId: number; testId: number }) => client.testDestroy(data.projectId, data.testId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['test-list']);
      }
    }
  );
}
