import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useJobDestroyMutation, useJobListQuery } from '../api/job.js';
import { timeDuration, timeToNow } from '../components/utils.js';
import { useService } from '../providers/ServiceProvider.js';

function JobTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const destroyMutation = useJobDestroyMutation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteJob = (jobId: number) => {
    destroyMutation.mutate(jobId);
  };

  const handleOpenModal = (job: any) => {
    setSelectedJob(job);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const {
    data: jobs,
    isLoading,
    error
  } = useJobListQuery({ limit: rowsPerPage, offset: page * rowsPerPage });

  if (isLoading)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  if (error) return <div>Error: {error.message}</div>;
  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Job Type</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Time In Queue</TableCell>
              <TableCell>Error</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.id}</TableCell>
                <TableCell>
                  <Chip
                    label={job.status}
                    color={job.status === 'failed' ? 'error' : 'primary'}
                    size="small"
                  />
                </TableCell>
                <TableCell>{job.data.type}</TableCell>
                <TableCell>{timeToNow(job.createdDate)}</TableCell>
                <TableCell>{timeDuration(job.createdDate, job.updatedDate)}</TableCell>
                <TableCell>{job.startDate ? timeToNow(job.startDate) : 'N/A'}</TableCell>
                <TableCell>{job.error ? job.error.message : 'N/A'}</TableCell>
                <TableCell>
                  <ButtonGroup variant="outlined" size="small">
                    <Button onClick={() => handleOpenModal(job)}>View</Button>
                    <Button onClick={() => handleDeleteJob(job.id)}>Delete</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Job Details</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <strong>ID:</strong> {selectedJob?.id}
          </Typography>
          <Typography variant="body1">
            <strong>Status:</strong> {selectedJob?.status}
          </Typography>
          <Typography variant="body1">
            <strong>Job Type:</strong> {selectedJob?.data?.type}
          </Typography>
          <Typography variant="body1">
            <strong>Retry Count:</strong> {selectedJob?.retryCount}
          </Typography>
          <Typography variant="body1">
            <strong>Created Date:</strong> {selectedJob?.createdDate.toString()}
          </Typography>
          <Typography variant="body1">
            <strong>Start Date:</strong> {selectedJob?.startDate ? selectedJob.startDate : 'N/A'}
          </Typography>
          <Typography variant="body1">
            <strong>Duration:</strong> {timeDuration(selectedJob?.createdDate, selectedJob?.updatedDate)}
          </Typography>
          <Typography variant="body1">
            <strong>Data:</strong>
          </Typography>
          <Typography variant="body2" component="pre">
            {JSON.stringify(selectedJob?.data, null, 2)}
          </Typography>
          <Typography variant="body1">
            <strong>Result:</strong>
          </Typography>
          <Typography variant="body2" component="pre">
            {JSON.stringify(selectedJob?.result, null, 2)}
          </Typography>
          {selectedJob?.error && (
            <>
              <Typography variant="body1">
                <strong>Error Message:</strong> {selectedJob.error.message}
              </Typography>
              <Typography variant="body1" component="pre">
                <strong>Error Stack:</strong> {selectedJob.error.stack}
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Paper>
  );
}

function Distribution() {
  const [data, setData] = useState<{ type: string; count: number }[]>([]);
  const [loading, setLoading] = useState(true);
  const client = useService();

  // Fetch data from the endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.fetch('/api/jobs/distribution');
        setData(await response.json());
      } catch (error) {
        console.error('Error fetching distribution data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  // Display loading indicator while data is being fetched
  if (loading) {
    return <CircularProgress />;
  }

  // Display message if no data is available
  if (data.length === 0) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Type</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.type}>
                <TableCell>{item.type}</TableCell>
                <TableCell align="right">{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function AvgTime() {
  const [data, setData] = useState<{ type: string; average: number; count: number }[]>([]);
  const [loading, setLoading] = useState(true);
  const client = useService();

  // Fetch data from the endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.fetch('/api/jobs/average-job-time');
        setData(await response.json());
      } catch (error) {
        console.error('Error fetching average time data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  // Display loading indicator while data is being fetched
  if (loading) {
    return <CircularProgress />;
  }

  // Display message if no data is available
  if (data.length === 0) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Type</TableCell>
              <TableCell align="right">Average Duration</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.type}>
                <TableCell>{item.type}</TableCell>
                <TableCell align="right">{item.average}</TableCell>
                <TableCell align="right">{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function JobSummary() {
  const [data, setData] = useState<{ status: string; count: number }[]>([]);
  const [loading, setLoading] = useState(true);
  const client = useService();

  // Fetch data from the endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.fetch('/api/jobs/summary');
        setData(await response.json());
      } catch (error) {
        console.error('Error fetching job summary data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  // Display loading indicator while data is being fetched
  if (loading) {
    return <CircularProgress />;
  }

  // Display message if no data is available
  if (data.length === 0) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Status</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.status}>
                <TableCell>{item.status}</TableCell>
                <TableCell align="right">{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function AverageQueueTime() {
  const [averageQueueTime, setAverageQueueTime] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const client = useService();

  useEffect(() => {
    const fetchAverageQueueTime = async () => {
      try {
        const response = await client.fetch('/api/jobs/average-queue-time');
        const data = await response.json();
        setAverageQueueTime(Number(data.averageQueueTime));
      } catch (error) {
        console.error('Error fetching average queue time:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageQueueTime().catch(console.error);
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (averageQueueTime === null) {
    return <Typography>No data available</Typography>;
  }

  return <Typography variant="h6">Average Queue Time: {averageQueueTime} seconds</Typography>;
}

export function JobsView() {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <Tabs value={tabIndex} onChange={(e, val) => setTabIndex(val)}>
        <Tab label="Job List" />
        <Tab label="Job Summary" />
        <Tab label="Distribution" />
        <Tab label="Average Times" />
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {tabIndex === 0 && <JobTable />}
        {tabIndex === 1 && <JobSummary />}
        {tabIndex === 2 && <Distribution />}
        {tabIndex === 3 && (
          <>
            <AverageQueueTime />
            <AvgTime />
          </>
        )}
      </Box>
    </>
  );
}
