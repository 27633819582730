import { SignatureAlertAPI } from '@analyzer/client';
import { Alert, AlertTitle, Box, Card, CardContent, Chip, Divider, Paper, Typography } from '@mui/material';
import { getSeverityColor } from './severity.js';

function renderSignatureDetails(alert: SignatureAlertAPI) {
  switch (alert.signature.data.type) {
    case 'url':
      return (
        <Typography variant="body2">
          <strong>URL Pattern:</strong> {alert.signature.data.url}
        </Typography>
      );
    case 'content-hash':
      return (
        <Typography variant="body2">
          <strong>Content Hash:</strong> {alert.signature.data.hash}
        </Typography>
      );
    case 'content-matches':
      return (
        <Typography variant="body2">
          <strong>Content Pattern:</strong> {alert.signature.data.pattern}
        </Typography>
      );
    case 'ip':
      return (
        <Typography variant="body2">
          <strong>IP Address:</strong> {alert.signature.data.ip}
        </Typography>
      );
  }
}

function getSignatureTypeLabel(type: string) {
  switch (type) {
    case 'url':
      return 'URL Pattern Match';
    case 'content-hash':
      return 'Content Hash Match';
    case 'content-matches':
      return 'Content Pattern Match';
    case 'ip':
      return 'IP Address Match';
    default:
      return 'Unknown Match Type';
  }
}

function formatDate(value: number | string | Date) {
  return new Date(value).toLocaleString();
}

export function SignatureAlert({ alert }: { alert: SignatureAlertAPI }) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle sx={{ fontSize: '1.1rem' }}>
          {getSignatureTypeLabel(alert.signature.data.type)}
          <Chip
            label={alert.severity.toUpperCase()}
            color={getSeverityColor(alert.severity)}
            size="small"
            sx={{ ml: 2 }}
          />
        </AlertTitle>
        <Typography variant="body2">
          A {alert.signature.data.type} signature match was detected in the request.
        </Typography>
      </Alert>

      <Paper elevation={2}>
        <Card>
          <CardContent>
            <Box sx={{ '& > :not(:last-child)': { mb: 3 } }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {alert.signature.description}
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="h6" gutterBottom>
                  Signature Details
                </Typography>
                <Box sx={{ '& > :not(:last-child)': { mb: 1 } }}>
                  <Typography variant="body2">
                    <strong>Type:</strong> {getSignatureTypeLabel(alert.signature.data.type)}
                  </Typography>
                  {renderSignatureDetails(alert)}
                  <Typography variant="body2">
                    <strong>Created:</strong> {formatDate(alert.signature.createdDate)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Signature ID:</strong> {alert.signature.id}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box>
                <Typography variant="h6" gutterBottom>
                  Request Details
                </Typography>
                <Box sx={{ '& > :not(:last-child)': { mb: 1 } }}>
                  <Typography variant="body2">
                    <strong>URL:</strong> {alert.request.request.url}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Method:</strong> {alert.request.request.method}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Content Type:</strong> {alert.request.response.contentType}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Host:</strong> {alert.request.connection.hostname}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Port:</strong> {alert.request.connection.port}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Secure:</strong> {alert.request.connection.secure ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
