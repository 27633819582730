import {
  Backdrop,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAssetListQuery } from '../api/asset.js';

export function AssetView() {
  return <div>Asset</div>;
}

export function AssetsView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter');

  function handleCreateClick(): void {
    throw new Error('Function not implemented.');
  }

  const { data: assets, isLoading, isError } = useAssetListQuery(parseInt(projectId));
  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !assets) {
    return <Typography variant="body1">Unable to load assets. Please try again later.</Typography>;
  }

  // Sort by descending date.
  assets.sort((a, b) => b.updatedDate.getTime() - a.updatedDate.getTime());

  return (
    <Paper elevation={1} sx={{ padding: 2 }}>
      <Typography variant="h5">Assets</Typography>
      <Button variant="contained" color="primary" onClick={handleCreateClick} size="small">
        Create
      </Button>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Fresh</TableCell>
              <TableCell>Hostname</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Port</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset) => (
              <TableRow key={asset.id}>
                <TableCell>{asset.id}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={asset.status}
                    color={asset.status === 'active' ? 'success' : 'error'}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={asset.isFresh() ? 'fresh' : 'not fresh'}
                    color={asset.isFresh() ? 'success' : 'error'}
                  />
                </TableCell>

                <TableCell>{asset.hostname}</TableCell>
                <TableCell>{asset.address}</TableCell>
                <TableCell>{asset.port}</TableCell>
                <TableCell>{asset.updatedDate.toLocaleDateString()}</TableCell>
                <TableCell>
                  <ButtonGroup size="small">
                    <Button>Edit</Button>
                    <Button>Delete</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
