import { Backdrop, CircularProgress } from '@mui/material';
import { useProjectListQuery } from '../api/project.js';

export function AlertsView() {
  const { data: projects, isLoading, isError } = useProjectListQuery();

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !projects) {
    return <div>Error occurred while fetching projects</div>;
  }

  // TODO: Implement
  return <pre>{JSON.stringify(projects, null, 2)}</pre>;
}
