import { useQuery } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider.js';

const FIVE_MINUTES = 1000 * 60 * 5;
const ONE_HOUR = 1000 * 60 * 60;

export function metricsReadQuery() {
  const client = useService();
  return useQuery<any, Error>({
    queryKey: ['metrics'],
    queryFn: () => client.readMetrics(),
    // Caching configuration
    staleTime: FIVE_MINUTES, // Consider data fresh for 5 minutes
    cacheTime: ONE_HOUR, // Keep unused data in cache for 1 hour

    // Refetching behavior
    refetchOnMount: false, // Don't refetch when component mounts if data exists
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: true, // Do refetch when internet reconnects
    // Performance optimization
    keepPreviousData: true // Show old data while fetching new data
  });
}
