import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider.js';
import { Alert } from '@analyzer/client';

export function useAlertListQuery(
  projectId: number,
  analysisId: number,
  queryParams?: { limit?: number; offset?: number }
) {
  const client = useService();
  return useQuery<Alert[], Error>(
    ['alert-list', projectId, analysisId, queryParams],
    () => client.alertList(projectId, analysisId, queryParams),
    {
      keepPreviousData: true
    }
  );
}

export function useAlertReadQuery(projectId: number, analysisId: number, alertId: number) {
  const client = useService();
  return useQuery<Alert, Error>({
    queryKey: ['alert-read', projectId, analysisId, alertId],
    queryFn: () => client.alertRead(projectId, analysisId, alertId)
  });
}

export function useAlertDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { projectId: number; analysisId: number; alertId: number }) =>
      client.alertDestroy(data.projectId, data.analysisId, data.alertId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['alert-list']);
      }
    }
  );
}
