import DataObjectIcon from '@mui/icons-material/DataObject';
import {
  Alert,
  Avatar,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSnapshotListQuery } from '../../api/snapshot.js';
import { PaginatedList } from '../generic/PaginatedList';

function SnapshotListElement({ data }: { data: any }) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={`/snapshots/${data.id}`}>
        <ListItemAvatar>
          <Avatar>
            <DataObjectIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.id} secondary={`Created ${data.updatedDate}`} />
      </ListItemButton>
    </ListItem>
  );
}

export function SnapshotsList({ projectId }: { projectId: number }) {
  const query = useSnapshotListQuery('web', projectId);
  if (query.isLoading) return <CircularProgress />;
  if (query.isError) return <Alert severity="error">{`Error: ${query.error.message}`}</Alert>;

  return <PaginatedList data={query.data} ListElement={SnapshotListElement} />;
}
