import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
dayjs.extend(relativeTime);
dayjs.extend(duration);

export function decodeObjectId(objectId: string) {
  const timestampHexString = objectId.substring(0, 8);
  const timestamp = parseInt(timestampHexString, 16);
  return { id: objectId, date: dayjs(new Date(timestamp * 1000)).fromNow() };
}

export function asPEM(base64String: string): string {
  const beginCert = '-----BEGIN CERTIFICATE-----\n';
  const endCert = '\n-----END CERTIFICATE-----';
  const formattedBase64 = base64String.match(/.{1,64}/g)?.join('\n') ?? '';
  return beginCert + formattedBase64 + endCert;
}

export function timeDuration(d0: Date, d1: Date) {
  return dayjs.duration(dayjs(d0).diff(d1)).humanize();
}

export function timeToNow(timestamp: number | Date) {
  return dayjs(timestamp).fromNow();
}

export function getFormattedDateFromTimestamp(timestamp: number | Date) {
  return dayjs(timestamp).format('YYYY/MM/DD');
}

export function getFormattedDateFromIsoString(isoString: string | Date) {
  return dayjs(isoString).fromNow();
}

export function handleDownload(data: BlobPart, filename: string, contentType = 'application/octet-stream') {
  try {
    const blob = new Blob([data], { type: contentType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.style.display = 'none';
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
