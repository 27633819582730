import { Project, Test } from '@analyzer/client';
import { ArrowDownward, ArrowUpward, Delete, Edit, Search } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useProjectCreateMutation,
  useProjectDestroyMutation,
  useProjectPagination,
  useProjectReadQuery,
  useProjectUpdateMutation
} from '../api/project';
import { useTestCreateMutation } from '../api/test';
import { DeleteProjectModal } from '../components/project/modals/delete';
import { EditProjectModal } from '../components/project/modals/edit';
import { NewProjectsModal, OnboardProjectModal } from '../components/project/modals/new';
import { handleDownload, timeToNow } from '../components/utils';
import { useModalStateHandler } from '../hooks/useModalStateHandler';
import { useService } from '../providers/ServiceProvider';

interface ProjectTestPair {
  project: Pick<Project, 'name' | 'description'>;
  test: Pick<Test, 'name' | 'target'>;
}

export function ProjectsView() {
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const { data, isLoading, queryParams, setSort, setQueryParams } = useProjectPagination();
  const { data: currentProject } = useProjectReadQuery(currentProjectId);
  const projectUpdate = useProjectUpdateMutation();
  const projectDestroy = useProjectDestroyMutation();
  const projectCreate = useProjectCreateMutation();
  const testCreate = useTestCreateMutation();
  const client = useService();
  const { enqueueSnackbar } = useSnackbar();

  // Add debounce effect for search
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      search: debouncedSearch,
      offset: 0
    }));
  }, [debouncedSearch, setQueryParams]);

  const modals = useModalStateHandler({
    'onboard-project': false,
    'delete-project': false,
    'edit-project': false,
    'bulk-project': false
  });

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const handleSortChange = useCallback(
    (field: keyof Project) => {
      setSort(field, queryParams.sortOrder === 'ASC' ? 'DESC' : 'ASC');
    },
    [setSort, queryParams.sortOrder]
  );

  const handleOnboardClick = useCallback(() => modals.open('onboard-project'), [modals]);
  const handleBulkCreateClick = useCallback(() => modals.open('bulk-project'), [modals]);

  const handleFileImport = useCallback(
    async (file: File) => {
      if (file.type !== 'application/json') {
        enqueueSnackbar('Only JSON files are supported', { variant: 'warning' });
        return;
      }
      try {
        const content = await file.text();
        await client.projectImport(JSON.parse(content));
        enqueueSnackbar('Projects imported successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Failed to import projects', { variant: 'error' });
      }
    },
    [client, enqueueSnackbar]
  );

  const handleImportClick = useCallback(async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) await handleFileImport(file);
    };
    input.click();
  }, [handleFileImport]);

  const handleExportClick = useCallback(async () => {
    try {
      const projects = await client.projectExport();
      const date = new Date().toISOString().slice(0, 10);
      const filename = `exported-projects-${date}.json`;
      handleDownload(JSON.stringify(projects, null, 2), filename);
      enqueueSnackbar('Projects exported successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to export projects', { variant: 'error' });
    }
  }, [client, enqueueSnackbar]);

  const handleEditClick = useCallback(
    (id: number) => {
      setCurrentProjectId(id);
      modals.open('edit-project');
    },
    [modals]
  );

  const handleDeleteClick = useCallback(
    (id: number) => {
      setCurrentProjectId(id);
      modals.open('delete-project');
    },
    [modals]
  );

  const handleOnboardProject = async (url: URL) => {
    try {
      const job = await client.onboardCreate(url);
      enqueueSnackbar(`Onboarding job (${job.id}) created for ${url}`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to create onboarding job`, { variant: 'error' });
    }
  };

  const handleEditProject = useCallback(
    (project: Partial<Project>) => {
      if (currentProjectId) {
        projectUpdate.mutate({ projectId: currentProjectId, project });
      }
    },
    [currentProjectId, projectUpdate]
  );

  const handleDeleteProject = useCallback(() => {
    if (currentProjectId) {
      projectDestroy.mutate(currentProjectId);
    }
  }, [currentProjectId, projectDestroy]);

  const handleBulkCreate = useCallback(
    async (projectTestPairs: ProjectTestPair[]) => {
      const results = await Promise.allSettled(
        projectTestPairs.map(async ({ project, test }) => {
          const newProject = await projectCreate.mutateAsync({ ...project });
          await testCreate.mutateAsync({ projectId: newProject.id, test });
          return newProject.name;
        })
      );

      const succeeded = results.filter((r) => r.status === 'fulfilled').length;
      const failed = results.filter((r) => r.status === 'rejected').length;

      enqueueSnackbar(`Created ${succeeded} projects${failed > 0 ? `, ${failed} failed` : ''}`, {
        variant: succeeded > 0 ? 'success' : 'error'
      });
    },
    [projectCreate, testCreate, enqueueSnackbar]
  );

  if (isLoading && !data) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const projects = data?.data ?? [];
  const metadata = data?.metadata;

  return (
    <>
      <OnboardProjectModal
        open={modals.state['onboard-project']}
        onClose={() => modals.close('onboard-project')}
        onConfirm={handleOnboardProject}
      />
      {currentProject && (
        <EditProjectModal
          project={currentProject}
          open={modals.state['edit-project']}
          onClose={() => modals.close('edit-project')}
          onConfirm={handleEditProject}
        />
      )}
      {currentProject && (
        <DeleteProjectModal
          project={currentProject}
          open={modals.state['delete-project']}
          onClose={() => modals.close('delete-project')}
          onConfirm={handleDeleteProject}
        />
      )}
      <NewProjectsModal
        open={modals.state['bulk-project']}
        onClose={() => modals.close('bulk-project')}
        onConfirm={handleBulkCreate}
      />
      <Stack spacing={4}>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {!metadata?.search
              ? `Tracking ${metadata?.total ?? 0} project(s)`
              : `Matched ${metadata?.total ?? 0} project(s)`}
          </Typography>

          <Stack spacing={2} direction="row">
            <TextField
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search Projects"
              variant="outlined"
              size="small"
              sx={{ minWidth: '300px' }}
              InputProps={{ endAdornment: <Search /> }}
            />
            <Button variant="contained" color="primary" onClick={handleOnboardClick}>
              Onboard
            </Button>
            <Button variant="contained" color="primary" onClick={handleBulkCreateClick}>
              Bulk Create
            </Button>
            <Button variant="contained" color="secondary" onClick={handleImportClick}>
              Import
            </Button>
            <Button variant="contained" color="success" onClick={handleExportClick}>
              Export
            </Button>
          </Stack>
        </Stack>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <SortableTableCell
                  field="name"
                  currentSort={queryParams.sortField || 'createdDate'}
                  sortOrder={queryParams.sortOrder || 'DESC'}
                  onSort={handleSortChange}
                >
                  Name
                </SortableTableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell align="center">Roots</TableCell>
                <TableCell align="center">Tests</TableCell>
                <TableCell align="center">Assets</TableCell>
                <TableCell align="center">Domains</TableCell>
                <TableCell align="center">Filters</TableCell>
                <SortableTableCell
                  field="createdDate"
                  currentSort={queryParams.sortField || 'createdDate'}
                  sortOrder={queryParams.sortOrder || 'DESC'}
                  onSort={handleSortChange}
                >
                  Date
                </SortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map(
                ({ id, name, description, roots, tests, createdDate, assets, domains, rules }) => (
                  <TableRow key={id} hover>
                    <TableCell>
                      <Link to={`/projects/${id}`}>{name}</Link>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={description}>
                        <span>
                          {description.substring(0, 60)}
                          {description.length > 60 ? '...' : ''}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => handleEditClick(id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleDeleteClick(id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">{roots.length}</TableCell>
                    <TableCell align="center">{tests.length}</TableCell>
                    <TableCell align="center">{assets.length}</TableCell>
                    <TableCell align="center">{domains.length}</TableCell>
                    <TableCell align="center">{rules.length}</TableCell>
                    <TableCell>{timeToNow(createdDate)}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={Math.ceil((metadata?.total ?? 0) / (queryParams.limit ?? 10))}
          page={(queryParams.offset ?? 0) / (queryParams.limit ?? 10) + 1}
          onChange={(_, page) => {
            const newOffset = (page - 1) * (queryParams.limit ?? 10);
            setQueryParams((prev) => ({ ...prev, offset: newOffset }));
          }}
          showFirstButton
          showLastButton
        />
      </Stack>
    </>
  );
}

interface SortableTableCellProps {
  field: keyof Project;
  currentSort: keyof Project;
  sortOrder: 'ASC' | 'DESC';
  onSort: (field: keyof Project) => void;
  children: React.ReactNode;
}

function SortableTableCell({ field, currentSort, sortOrder, onSort, children }: SortableTableCellProps) {
  return (
    <TableCell onClick={() => onSort(field)} sx={{ cursor: 'pointer', userSelect: 'none' }}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {children}
        {currentSort === field &&
          (sortOrder === 'ASC' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)}
      </Stack>
    </TableCell>
  );
}
