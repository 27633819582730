import { Alert, AlertTitle, Box, Card, CardContent, Chip, Divider, Paper, Typography } from '@mui/material';
import { getSeverityColor } from './severity.js';
import { DependencyChangeAlertAPI } from '@analyzer/client';

export function DependencyChangeAlert({ alert }: { alert: DependencyChangeAlertAPI }) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle sx={{ fontSize: '1.1rem' }}>
          Dependency Change Detected
          <Chip
            label={alert.severity.toUpperCase()}
            color={getSeverityColor(alert.severity)}
            size="small"
            sx={{ ml: 2 }}
          />
        </AlertTitle>
        <Typography variant="body2">
          A new dependency was detected in the request, which may indicate a change in the website's behavior.
        </Typography>
      </Alert>

      <Paper elevation={2}>
        <Card>
          <CardContent>
            <Box sx={{ '& > :not(:last-child)': { mb: 3 } }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  This alert is triggered when the website has new requests to external resources.
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="h6" gutterBottom>
                  Impact
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The addition of new dependencies could indicate changes in the website's behavior or
                  potential security risks. These new dependencies should be reviewed to ensure they are
                  expected and don’t introduce any vulnerabilities or malicious behavior.
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="h6" gutterBottom>
                  Details
                </Typography>
                <Typography variant="body2">New origins were detected:</Typography>
                <ul>
                  {alert.origins.map((origin, index: number) => (
                    <li key={index}>{origin.request.url}</li>
                  ))}
                </ul>

                <Typography variant="body2" sx={{ mt: 2 }}>
                  Previous origins:
                </Typography>
                <ul>
                  {alert.history.map((origin: string, index: number) => (
                    <li key={index}>{origin}</li>
                  ))}
                </ul>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
