import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRootListQuery } from '../api/root.js';

export function RootView() {
  return <div>Root</div>;
}

export function RootsView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter');

  function handleCreateClick(): void {
    throw new Error('Function not implemented.');
  }

  const { data: roots, isLoading, isError } = useRootListQuery(parseInt(projectId));
  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !roots) {
    return <Typography variant="body1">Unable to load roots. Please try again later.</Typography>;
  }

  return (
    <Paper elevation={1} sx={{ padding: 2 }}>
      <Typography variant="h5">Roots</Typography>
      <Button variant="contained" color="primary" onClick={handleCreateClick} size="small">
        Create
      </Button>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Hostname</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roots.map((root) => (
              <TableRow key={root.id}>
                <TableCell>{root.id}</TableCell>
                <TableCell>{root.hostname}</TableCell>
                <TableCell>
                  <ButtonGroup size="small">
                    <Button>Edit</Button>
                    <Button>Delete</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
