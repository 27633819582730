import { AntiDebugAlertAPI } from '@analyzer/client';
import { CallFrame } from '@analyzer/core/lib/collector/plugins/debug/index.js';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Link,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { getSeverityColor } from './severity.js';

const renderCallStack = (frames: CallFrame[]) => {
  return frames.map((frame, index) => (
    <Box
      key={index}
      sx={{
        p: 1.5,
        borderBottom: '1px solid',
        borderColor: 'divider',
        '&:last-child': { borderBottom: 'none' },
        '&:hover': { bgcolor: 'action.hover' }
      }}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Box>
          <Typography variant="subtitle2">Function: {frame.functionName || '<anonymous>'}</Typography>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 0.5 }}>
            <Typography variant="body2" color="text.secondary">
              Source:
            </Typography>
            <Link
              href={frame.url}
              color="primary"
              underline="hover"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                fontSize: '0.875rem'
              }}
            >
              {frame.source || frame.url || 'unknown source'}
            </Link>
            <Typography variant="body2" color="text.secondary">
              line {frame.lineNumber}, column {frame.columnNumber}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  ));
};

export function AntiDebugAlert({ alert }: { alert: AntiDebugAlertAPI }) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle sx={{ fontSize: '1.1rem' }}>
          Anti Debugging Measures Detected
          <Chip
            label={alert.severity.toUpperCase()}
            color={getSeverityColor(alert.severity)}
            size="small"
            sx={{ ml: 2 }}
          />
        </AlertTitle>
        <Typography variant="body2">Anti debugging measures detected.</Typography>
      </Alert>

      <Paper elevation={2}>
        <Card>
          <CardContent>
            <Box sx={{ '& > :not(:last-child)': { mb: 3 } }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  This page implements measures to prevent debugging and analysis. This could indicate
                  attempts to hide malicious behavior or protect intellectual property.
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="h6" gutterBottom>
                  Call Stack Details
                </Typography>
                <Paper
                  variant="outlined"
                  sx={{
                    mt: 1,
                    maxHeight: 400,
                    overflow: 'auto'
                  }}
                >
                  {renderCallStack(alert.data.frames)}
                </Paper>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
