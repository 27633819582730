import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useProjectDestroyMutation } from '../../api/project.js';

interface Properties {
  projectId: number;
}

export function DestroyProjectComponent({ projectId }: Properties) {
  const [open, setOpen] = useState(false);
  const [destroyed, setDestroyed] = useState(false);
  const projectDestroy = useProjectDestroyMutation();

  async function handler() {
    try {
      await projectDestroy.mutateAsync(projectId);
      setDestroyed(true);
      setOpen(true);
    } catch (error) {
      console.error('Failed to destroy project:', error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  if (destroyed) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Button disabled={projectDestroy.isLoading} onClick={handler}>
        Destroy
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Project destroyed successfully"
      />
    </>
  );
}
