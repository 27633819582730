import { DomainUtils } from '@analyzer/utilities/lib/domain.js';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

interface Project {
  name: string;
  description: string;
}

interface Test {
  name: string;
  target: string;
}

interface OnboardProjectModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (url: URL) => Promise<void>;
}

interface NewProjectsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (projectTestPairs: { project: Project; test: Test }[]) => Promise<void>;
}

// Utility functions
const isValidUrl = (url: string): boolean => {
  try {
    void new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

const createProjectFromUrl = (url: string): { project: Project; test: Test } => {
  const name = DomainUtils.getDomain(url);
  return {
    project: {
      name,
      description: `Project for ${name}`
    },
    test: {
      name: 'Default Test',
      target: url
    }
  };
};

// Single Project Onboarding Modal
export function OnboardProjectModal({ open, onClose, onConfirm }: OnboardProjectModalProps) {
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    setUrl('');
    setUrlError('');
    onClose();
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newUrl = event.target.value;
    setUrl(newUrl);

    // Validate URL as user types
    if (newUrl && !isValidUrl(newUrl)) {
      setUrlError('Please enter a valid URL');
    } else {
      setUrlError('');
    }
  };

  const handleCreate = async () => {
    if (!url || !isValidUrl(url)) {
      setUrlError('Please enter a valid URL.');
      return;
    }

    try {
      setIsLoading(true);
      const urlObj = new URL(url);
      await onConfirm(urlObj);
      handleCancel();
    } catch (error) {
      setUrlError('Failed to create project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Onboard Project</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Typography variant="body1">
            Enter your website URL to start onboarding. We'll scan your site, find relevant pages, create a
            project for your domain, and set up tests for those pages automatically.
          </Typography>
          <TextField
            label="Project URL"
            value={url}
            onChange={handleUrlChange}
            fullWidth
            error={!!urlError}
            helperText={urlError}
            placeholder="https://example.com"
            disabled={isLoading}
            autoFocus
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleCreate} disabled={!url || !!urlError || isLoading} variant="contained">
          {isLoading ? <CircularProgress size={24} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// Bulk Projects Modal with form handling
interface NewProjectsFormValues {
  urls: string;
}

export function NewProjectsModal({ open, onClose, onConfirm }: NewProjectsModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<NewProjectsFormValues>();

  const handleConfirm = async (data: NewProjectsFormValues) => {
    try {
      setIsLoading(true);
      const urlList = data.urls
        .split('\n')
        .map((url) => url.trim())
        .filter((url) => url !== '' && isValidUrl(url));

      if (urlList.length === 0) {
        throw new Error('No valid URLs found');
      }

      const projectTestPairs = urlList.map((url) => createProjectFromUrl(url));
      await onConfirm(projectTestPairs);
      reset();
      onClose();
    } catch (error) {
      console.error('Failed to create projects:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Add Bulk Projects</DialogTitle>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Typography variant="body1">
              Enter multiple URLs, each on a new line, to create multiple projects at once. For each URL, a
              new project and an associated test will be created.
            </Typography>
            <TextField
              label="URLs"
              multiline
              rows={14}
              {...register('urls', {
                required: 'Please enter at least one URL',
                validate: (value) => {
                  const urls = value.split('\n').filter((url) => url.trim());
                  return urls.some((url) => isValidUrl(url)) || 'Please enter at least one valid URL';
                }
              })}
              error={!!errors.urls}
              helperText={errors.urls?.message}
              disabled={isLoading}
              placeholder="https://example1.com&#10;https://example2.com&#10;https://example3.com"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} variant="contained">
            {isLoading ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
