import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useRuleListQuery(projectId: number, queryParams?: { limit?: number; offset?: number }) {
  const client = useService();
  return useQuery<any[], Error>(
    ['rule-list', projectId, queryParams],
    () => client.ruleList(projectId, queryParams),
    {
      keepPreviousData: true
    }
  );
}

export function useRuleCreateMutation(projectId: number) {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((rule: { predicate: any; action: any }) => client.ruleCreate(projectId, rule), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['rule-list', projectId]);
    }
  });
}

export function useRuleReadQuery(projectId: number, ruleId: number) {
  const client = useService();
  return useQuery<any, Error>({
    queryKey: ['rule-read', projectId, ruleId],
    queryFn: () => client.ruleRead(projectId, ruleId)
  });
}

export function useRuleUpdateMutation(projectId: number, ruleId: number) {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((rule: { predicate: any; action: any }) => client.ruleUpdate(projectId, ruleId, rule), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['rule-list', projectId]);
      await queryClient.invalidateQueries(['rule-read', projectId, ruleId]);
    }
  });
}

export function useRuleDestroyMutation(projectId: number) {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((ruleId: number) => client.ruleDestroy(projectId, ruleId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['rule-list', projectId]);
    }
  });
}
