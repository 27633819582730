import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSnapshotCreateMutation } from '../api/snapshot.js';
import {
  useTestCreateMutation,
  useTestDestroyMutation,
  useTestListQuery,
  useTestReadQuery,
  useTestUpdateMutation
} from '../api/test.js';
import { AnalysisList } from '../components/analysis/list';
import { DestroyProjectComponent } from '../components/project/destroy';
import { SnapshotsList } from '../components/snapshot/list';
import { NewSnapshotComponent } from '../components/snapshot/new';
import { DeleteTestDialog } from '../components/test/dialogs/delete.js';
import { EditTestDialog } from '../components/test/dialogs/edit.js';
import { NewTestDialog } from '../components/test/dialogs/new.js';
import { useModalStateHandler } from '../hooks/useModalStateHandler.js';

export function TestsView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter');

  const [currentTestId, setCurrentTestId] = useState<number | null>(null);

  const { data: tests, isLoading, isError } = useTestListQuery(parseInt(projectId));
  const currentTest = useTestReadQuery(parseInt(projectId), currentTestId);
  const testDestroy = useTestDestroyMutation();
  const testCreate = useTestCreateMutation();
  const testUpdate = useTestUpdateMutation();
  const snapshotCreate = useSnapshotCreateMutation('web');

  const navigate = useNavigate();

  const modals = useModalStateHandler({
    'delete-test': false,
    'new-test': false,
    'edit-test': false,
    'run-test': false
  });

  const handleCreateClick = () => {
    modals.open('new-test');
  };

  const handleEditClick = (id: number) => {
    setCurrentTestId(id);
    modals.open('edit-test');
  };

  const handleDeleteClick = (id: number) => {
    setCurrentTestId(id);
    modals.open('delete-test');
  };

  function handleSnapshotClick(projectId: number, testId: number): void {
    snapshotCreate.mutate({ projectId, assetId: testId });
  }

  const newTestDialog = (
    <NewTestDialog
      open={modals.state['new-test']}
      onClose={() => {
        modals.close('new-test');
      }}
      onConfirm={(test) => {
        testCreate
          .mutateAsync({ projectId: parseInt(projectId), test })
          .then((test) => navigate(`/projects/${projectId}/tests/${test.id}`))
          .catch(() => {});
      }}
    />
  );

  const editTestDialog = (
    <EditTestDialog
      test={currentTest.data}
      open={modals.state['edit-test']}
      onClose={() => {
        modals.close('edit-test');
      }}
      onConfirm={(test) => {
        testUpdate.mutate({ projectId: parseInt(projectId), testId: currentTestId!, test });
      }}
    />
  );

  const deleteTestDialog = (
    <DeleteTestDialog
      test={currentTest.data}
      open={modals.state['delete-test']}
      onClose={() => {
        modals.close('delete-test');
      }}
      onConfirm={() => {
        testDestroy.mutate({ projectId: parseInt(projectId), testId: currentTestId! });
      }}
    />
  );

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !tests) {
    return <Typography variant="body1">Unable to load tests. Please try again later.</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5">Tests</Typography>
      <Button variant="contained" color="primary" onClick={handleCreateClick} size="small">
        Create
      </Button>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests.map((test) => (
              <TableRow key={test.id}>
                <TableCell>{test.id}</TableCell>
                <TableCell>{test.name}</TableCell>
                <TableCell>
                  <Link to={test.target}>{test.target}</Link>
                </TableCell>
                <TableCell>
                  <ButtonGroup size="small">
                    <Button onClick={() => handleSnapshotClick(parseInt(projectId), test.id)}>
                      Snapshot
                    </Button>
                    <Button onClick={() => handleEditClick(test.id)}>Edit</Button>
                    <Button onClick={() => handleDeleteClick(test.id)}>Delete</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modals.state['new-test'] && newTestDialog}
      {modals.state['edit-test'] && editTestDialog}
      {modals.state['delete-test'] && deleteTestDialog}
    </Box>
  );
}

type TestViewParams = {
  projectId: string;
  testId: string;
};

export function TestView() {
  const { projectId, testId } = useParams<TestViewParams>();
  if (!testId || !projectId) throw new Error('Missing parameter');

  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const test = useTestReadQuery(parseInt(projectId), parseInt(testId));
  if (test.isLoading) return <CircularProgress />;
  if (test.isError) return <Alert severity="error">{`Error: ${test.error.message}`}</Alert>;

  return (
    <Box>
      <TabContext value={value.toString()}>
        <TabList value={value} onChange={handleChange}>
          <Tab label="Test" value="0" />
          <Tab label="Snapshots" value="1" />
          <Tab label="Analyses" value="2" />
        </TabList>

        <TabPanel value="0">
          <Box sx={{ display: 'flex' }}>
            <NewSnapshotComponent type="web" projectId={parseInt(projectId)} testId={parseInt(testId)} />
            <DestroyProjectComponent projectId={parseInt(projectId)} />
          </Box>

          <Typography variant="h5">Test Details</Typography>
          <Typography>Name</Typography>
          <p>{test.data.name}</p>
          <Typography>Description</Typography>
          <p>{test.data.target}</p>
          <Typography>Actions</Typography>
          <pre>{test.data.actions ?? 'default'}</pre>
          <Typography>Scripts</Typography>
          <pre>{test.data.initScript ?? 'none'}</pre>
        </TabPanel>

        <TabPanel value="1">
          <SnapshotsList projectId={parseInt(projectId)} />
        </TabPanel>

        <TabPanel value="2">
          <AnalysisList type="web" projectId={parseInt(projectId)} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
