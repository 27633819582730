import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { metricsReadQuery } from '../api/metrics.js';
import DailyMetricsGraph from '../components/MetricsGraph.js';
import { FRONTEND_BUILD_STRING, FRONTEND_SERVICE_URL } from '../settings.js';

export function MainView() {
  const { data: metrics, isLoading, isError, error } = metricsReadQuery();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error" variant="h6">
          Error: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Container>
      <Grid container spacing={4}>
        {/* Overview */}
        <Grid item xs={12}>
          <Typography variant="h6">Overview</Typography>
          <Typography variant="body1">Service URL: {FRONTEND_SERVICE_URL}</Typography>
          <Typography variant="body1">Build String: {FRONTEND_BUILD_STRING}</Typography>
        </Grid>

        {['project', 'snapshot', 'analysis', 'alert'].map((category) => (
          <Grid item key={category} xs={3}>
            <Typography variant="h6">{category.toUpperCase()}:</Typography>
            <Typography variant="body2">Count: {metrics[category].count}</Typography>
          </Grid>
        ))}

        {/* Daily Graphs */}
        <Grid item xs={4}>
          <Typography variant="h6">Snapshots</Typography>
          <DailyMetricsGraph metrics={metrics.snapshot} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Analyses</Typography>
          <DailyMetricsGraph metrics={metrics.analysis} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Alerts</Typography>
          <DailyMetricsGraph metrics={metrics.alert} />
        </Grid>
      </Grid>
    </Container>
  );
}
