import { Alert, CircularProgress, Container, SxProps, Theme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAlertReadQuery } from '../api/alert.js';
import { AlertComponent } from '../components/alert/view.js';

export function AlertView() {
  const { projectId, analysisId, alertId } = useParams();
  if (!projectId || !analysisId || !alertId) throw new Error('Missing parameter');

  const alert = useAlertReadQuery(parseInt(projectId), parseInt(analysisId), parseInt(alertId));
  if (alert.isLoading) return <CircularProgress />;
  if (alert.isError) return <Alert severity="error">{`Error: ${alert.error}`}</Alert>;

  const style: SxProps<Theme> = {
    overflowWrap: 'break-word'
  };

  return (
    <Container sx={style}>
      <AlertComponent alert={alert.data} />
    </Container>
  );
}
