import { Analysis, AnalysisType, Job } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useAnalysisListQuery(
  type: AnalysisType,
  projectId: number,
  queryParams?: { limit?: number; offset?: number }
) {
  const client = useService();
  return useQuery<Analysis[], Error>(
    [`${type}-analysis-list`, projectId, queryParams],
    () => client.analysisList(type, projectId, queryParams),
    {
      keepPreviousData: true
    }
  );
}

interface CreateArguments {
  projectId: number;
  snapshotId: number;
}

export function useAnalysisCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation<Job, Error, CreateArguments>({
    mutationFn: async ({ projectId, snapshotId }) => client.analysisCreate(projectId, snapshotId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([`analysis-list`]);
    }
  });
}

export function useAnalysisReadQuery(projectId: number, analysisId: number) {
  const client = useService();
  return useQuery<Analysis, Error>({
    queryKey: [`analysis-read`, projectId, analysisId],
    queryFn: () => client.analysisRead(projectId, analysisId)
  });
}

interface DestroyArguments {
  projectId: number;
  analysisId: number;
}

export function useAnalysisDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation<boolean, Error, DestroyArguments>({
    mutationFn: async ({ projectId, analysisId }) => client.analysisDestroy(projectId, analysisId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([`analysis-list`]);
    }
  });
}
